const firebaseConfig = {
  apiKey: "AIzaSyD2NhgTrW7BqRqTpJ83jOVTKSXC91cOjJU",
  authDomain: "public-house-virtual-mansion.firebaseapp.com",
  projectId: "public-house-virtual-mansion",
  storageBucket: "public-house-virtual-mansion.appspot.com",
  messagingSenderId: "102237273289",
  appId: "1:102237273289:web:0b0ef7f748009d9d512118",
  measurementId: "G-JKQMLY6J5Y",
};

export default firebaseConfig;
