import { useState, useEffect } from "react";
import { Button, Container, Paper, TextField } from "@material-ui/core";
import ImageMapper from "react-image-mapper";
import map from "../images/map.png";
// import mapLocations from "../images/map-locations.png";
import { useDocument, useCollection } from "@nandorojo/swr-firestore";
import { ImageMapMain, ImageMap2 } from "../components/ImageMap";
import { useAuth } from "../hooks/use-auth";
import useViewport from "../hooks/use-viewport";

const GUILD_ID = "802727441646092288";

const ELLINGSON = "807844030465310762";
const ACID_BURN = "807856545853079562";
const HIGH_SCHOOL = "819089903512977416";
const CYBERDELIA = "815452324377002026";

const FLOPPY_DISK = "819451249308598282";
const PARTY_FLYER = "819446676090781728";

function Map({ memberId }) {
  const firebase = useAuth();
  const { data } = useDocument(
    firebase && firebase.user ? `/users/${firebase.user.uid}` : null,
    { listen: true }
  );
  const { data: channels } = useCollection(
    firebase && firebase.user ? `/guilds/${GUILD_ID}/channels` : null,
    { listen: true }
  );
  const { data: members } = useCollection(
    firebase && firebase.user ? `/guilds/${GUILD_ID}/members` : null,
    { listen: true }
  );
  const { data: me } = useDocument(
    firebase && firebase.user
      ? `/guilds/${GUILD_ID}/members/${firebase.user.uid}`
      : null,
    { listen: true }
  );

  const { width } = useViewport();

  useEffect(() => {
    console.log("channels", channels);
  }, [channels]);

  useEffect(() => {
    console.log("members", members);
  }, [members]);

  useEffect(() => {
    console.log("user", firebase.user);
  }, [firebase]);

  const handleMapClick = (me, member, channel) => {
    //ellingson
    console.log(me);
    if (channel === ELLINGSON && !me.roles.includes(FLOPPY_DISK)) {
      alert("Access Denied!");
    } else if (channel === ACID_BURN && !me.roles.includes(PARTY_FLYER)) {
      alert("Access Denied!");
    } else {
      firebase.fuego
        .functions()
        .httpsCallable("map-moveMember")({
          guild_id: GUILD_ID,
          member_id: member,
          channel_id: channel,
        })
        .then((result) => {
          console.log(result);
        });
    }
  };

  const getMember = (id, members) => {
    return members.find((member) => {
      console.log("member", id, member.user.id, member.user.id === id);
      return member.user.id === id;
    });
  };

  return (
    <>
      <div
        style={{
          width: Math.min(width * 0.95, 1200),
          position: "relative",
        }}
      >
        {/* <img
          alt="map"
          src={map}
          width={Math.min(width * 0.95, 1200)}
          style={{ position: "absolute", top: 0, left: 0 }}
        /> */}
        <ImageMapper
          width={Math.min(width * 0.95, 1200)}
          imgWidth={1228}
          src={map}
          map={ImageMapMain}
          onClick={(area) => handleMapClick(me, memberId, area.channelId)}
        />
      </div>
      {/* <ImageMapper
        width={Math.min(width * 0.95, 600)}
        imgWidth={2787}
        src={floor2}
        map={ImageMap2}
        onClick={(area) => handleMapClick(memberId, area.channelId)}
      /> */}
      {channels && members && (
        <div style={{ textAlign: "left" }}>
          <h3>Rooms</h3>
          <ul>
            {channels.map((channel) =>
              channel.members.length ? (
                <li>
                  {channel.name}
                  <ul>
                    {channel.members.map((member) => (
                      <li>
                        {getMember(member, members) &&
                          (getMember(member, members).nick ||
                            getMember(member, members).user.username)}
                        ({member})
                      </li>
                    ))}
                  </ul>
                </li>
              ) : null
            )}
          </ul>
        </div>
      )}
      <br /> <br />
    </>
  );
}

export default Map;
