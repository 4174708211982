import React, { useEffect } from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { useAuth } from "../hooks/use-auth";
import { makeStyles } from "@material-ui/core/styles";
import {
  IconButton,
  Button,
  Typography,
  AppBar,
  Toolbar,
  Container,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import useRouter from "../hooks/use-router.js";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { useCookies } from "react-cookie";

const useStyles = makeStyles((theme) => ({
  root: {},
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: { alignSelf: "center", flex: 1 },
  tabs: {
    alignSelf: "center",
    justifySelf: "center",
    flex: 1,
  },

  loginLogout: {
    alignSelf: "center",
    justifySelf: "flex-end",
  },
}));

const routes = ["/home", "/questions", "/results", "/next"];

function NavBar({ removeCookie }) {
  const classes = useStyles();
  const auth = useAuth();
  const router = useRouter();

  const handleLogout = () => {
    removeCookie("token");
    auth.signout();
    // removeCookie("connect.sid");
  };

  return (
    <>
      <div className={classes.root}>
        <AppBar position="absolute">
          <Toolbar>
            {/* <IconButton
              edge="start"
              className={classes.menuButton}
              color="inherit"
              aria-label="menu"
            >
              <MenuIcon />
            </IconButton> */}
            <Typography variant="h6" className={classes.title}>
              Hack the Party!
            </Typography>
            {auth && auth.user ? (
              <>
                <Button
                  className={classes.loginLogout}
                  color="inherit"
                  onClick={handleLogout}
                >
                  Logout
                </Button>
              </>
            ) : null}
          </Toolbar>
        </AppBar>
        <Toolbar />
      </div>
    </>
  );
}

export default NavBar;
