// <!-- Image Map Generated by http://www.image-map.net/ -->
// <img src="map.png" usemap="#image-map">

// <map name="image-map">
//     <area target="" alt="cyberdelia" title="cyberdelia" href="" coords="429,722,36" shape="circle">
//     <area target="" alt="acid-burn" title="acid-burn" href="" coords="315,460,36" shape="circle">
//     <area target="" alt="ellignson" title="ellignson" href="" coords="485,466,33" shape="circle">
//     <area target="" alt="high school" title="high school" href="" coords="494,654,36" shape="circle">
// </map>

export const ImageMapMain = {
  name: "map",
  areas: [
    {
      name: "acid-burn",
      shape: "circle",
      channelId: "807856545853079562",
      coords: [315, 460, 36],
    },
    {
      name: "ellingson",
      shape: "circle",
      channelId: "807844030465310762",
      coords: [485, 466, 33],
    },
    {
      name: "high-school",
      shape: "circle",
      channelId: "819089903512977416",
      coords: [494, 654, 36],
    },
    {
      name: "cyberdelia",
      shape: "circle",
      channelId: "815452324377002026",
      coords: [429, 722, 36],
    },
  ],
};

/*

{
  name: "theater",
  shape: "poly",
  channelId: "792972925913399316",
  coords: [],
},

*/

export const ImageMap2 = {
  name: "upper-floor",
  areas: [
    {
      name: "Theater",
      shape: "poly",
      channelId: "792972925913399316",
      coords: [
        286,
        792,
        328,
        826,
        333,
        1001,
        812,
        1002,
        864,
        950,
        948,
        857,
        1035,
        775,
        1025,
        435,
        980,
        418,
        956,
        388,
        931,
        349,
        919,
        302,
        912,
        265,
        916,
        221,
        848,
        218,
        756,
        248,
        624,
        292,
      ],
    },
    {
      name: "Dustin's Room",
      shape: "poly",
      channelId: "793725632060194836",
      coords: [
        1434,
        1714,
        1392,
        1714,
        1180,
        1505,
        1183,
        1307,
        1174,
        1182,
        1171,
        922,
        1733,
        919,
        1745,
        1433,
        1437,
        1439,
      ],
    },

    {
      name: "Bathroom",
      shape: "poly",
      channelId: "793739712153911326",
      coords: [2509, 882, 2496, 566, 2506, 384, 2711, 606, 2718, 896],
    },
    {
      name: "Observatory",
      shape: "poly",
      channelId: "793709369086705737",
      coords: [
        1768,
        1133,
        1827,
        1054,
        1898,
        1000,
        1977,
        982,
        2093,
        1128,
        2083,
        1184,
        2053,
        1229,
        2021,
        1263,
        1987,
        1273,
        1938,
        1290,
        1903,
        1298,
        1854,
        1290,
        1815,
        1271,
        1785,
        1241,
        1763,
        1192,
      ],
    },
    {
      name: "Wardrobe",
      shape: "poly",
      channelId: "793717805169115176",
      coords: [
        1662,
        300,
        1728,
        352,
        1787,
        352,
        1812,
        325,
        1812,
        761,
        1726,
        741,
        1662,
        741,
      ],
    },
    {
      name: "Quiet Room",
      shape: "poly",
      channelId: "792997378293628958",
      coords: [
        1165,
        463,
        1155,
        699,
        1256,
        768,
        1637,
        766,
        1632,
        507,
        1637,
        288,
        1349,
        219,
        1312,
        224,
        1317,
        261,
        1310,
        298,
        1310,
        342,
        1288,
        382,
        1258,
        411,
        1238,
        428,
        1184,
        448,
        1214,
        441,
      ],
    },
    {
      name: "Turret",
      shape: "poly",
      channelId: "792973260815597569",
      coords: [
        1145,
        456,
        1184,
        446,
        1229,
        441,
        1256,
        406,
        1288,
        384,
        1300,
        347,
        1305,
        300,
        1310,
        239,
        1295,
        187,
        1273,
        153,
        1231,
        113,
        1187,
        86,
        1128,
        74,
        1073,
        74,
        1044,
        84,
        1019,
        101,
        985,
        123,
        955,
        153,
        936,
        187,
        916,
        241,
        918,
        308,
        940,
        374,
        985,
        421,
        1027,
        438,
      ],
    },
    {
      name: "Dance Hall",
      shape: "poly",
      channelId: "715829291144970355",
      coords: [629, 1149, 797, 1143, 967, 1257, 967, 1490, 626, 1487],
    },
    {
      name: "Jam Room",
      shape: "poly",
      channelId: "792998360407736352",
      coords: [
        312,
        1161,
        618,
        1158,
        615,
        1484,
        366,
        1493,
        286,
        1370,
        315,
        1319,
      ],
    },
    {
      name: "Bay Window Seat",
      shape: "poly",
      channelId: "793708380762013717",
      coords: [
        283,
        802,
        315,
        835,
        324,
        1032,
        310,
        1131,
        310,
        1313,
        280,
        1361,
        160,
        1188,
        160,
        957,
      ],
    },
    {
      name: "Large Table",
      shape: "poly",
      channelId: "792982727258931200",
      coords: [
        2181,
        625,
        2268,
        628,
        2250,
        371,
        2158,
        371,
        2077,
        377,
        2077,
        437,
        2080,
        539,
        2083,
        637,
        2125,
        625,
      ],
    },
    {
      name: "Medium Table",
      shape: "poly",
      channelId: "792996218666090507",
      coords: [
        1864,
        359,
        1864,
        467,
        1864,
        521,
        1864,
        577,
        1864,
        622,
        1885,
        628,
        1909,
        622,
        1936,
        622,
        1969,
        631,
        1996,
        634,
        2002,
        583,
        1996,
        527,
        1996,
        443,
        2005,
        368,
      ],
    },
    {
      name: "Small Table",
      shape: "poly",
      channelId: "792996270737457152",
      coords: [
        2361,
        482,
        2403,
        494,
        2436,
        485,
        2463,
        485,
        2466,
        437,
        2466,
        377,
        2421,
        377,
        2355,
        377,
        2358,
        410,
      ],
    },
    {
      name: "Jackbox Table",
      shape: "poly",
      channelId: "793695440373284864",
      coords: [
        2370,
        568,
        2412,
        562,
        2454,
        565,
        2457,
        592,
        2457,
        613,
        2460,
        646,
        2397,
        640,
        2373,
        643,
        2373,
        607,
      ],
    },
    // {
    //   name: "Blanket Fort",
    //   shape: "poly",
    //   channelId: "792996672740655145",
    //   coords: [
    //     1431,
    //     1720,
    //     1515,
    //     1795,
    //     1742,
    //     1789,
    //     1736,
    //     1439,
    //     1610,
    //     1514,
    //     1434,
    //     1439,
    //   ],
    // },
    // {
    //   name: "Sloped Roof",
    //   shape: "poly",
    //   channelId: "793710314741956648",
    //   coords: [
    //     2080,
    //     1115,
    //     2154,
    //     677,
    //     2371,
    //     665,
    //     2492,
    //     680,
    //     2501,
    //     884,
    //     2629,
    //     891,
    //     2730,
    //     904,
    //     2437,
    //     1130,
    //   ],
    // },
  ],
};

/*

{
  name: "theater",
  shape: "poly",
  channelId: "792972925913399316",
  coords: [],
},

*/
