import { Button } from "@material-ui/core";

function Welcome() {
  return (
    <>
      <br />
      <br />
      <br />
      <div style={{ textAlign: "center" }}>
        <Button
          size="large"
          variant="contained"
          href={
            process.env.NODE_ENV === "development"
              ? "http://localhost:5000/auth-api/login"
              : "/auth-api/login"
          }
        >
          Login with Discord
        </Button>
      </div>
    </>
  );
}

export default Welcome;
