import { useState, useEffect } from "react";
import { useCookies } from "react-cookie";
import "./App.css";
import { Button, Container, Paper, TextField } from "@material-ui/core";
import { useDocument } from "@nandorojo/swr-firestore";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  Link,
  useLocation,
} from "react-router-dom";
import { useAuth } from "./hooks/use-auth";
import NavBar from "./components/navBar";
import useViewport from "./hooks/use-viewport";
import Map from "./pages/map";
import Welcome from "./pages/welcome";

const GUILD_ID = "715829291144970351";

function App() {
  const [memberId, setMemberId] = useState("793650838257926164");
  const [cookies, setCookie, removeCookie] = useCookies([
    "token",
    // "connect.sid",
  ]);
  const firebase = useAuth();
  const { data } = useDocument(
    firebase && firebase.user ? `/users/${firebase.user.uid}` : null
  );

  const { width } = useViewport();

  useEffect(() => {
    if (firebase && firebase.user) {
      setMemberId(firebase.user.uid);
      // removeCookie("connect.sid");
    }
  }, [firebase]); // Only re-run the effect if count changes

  useEffect(() => {
    if (cookies.token && firebase && firebase.fuego && !firebase.user) {
      firebase.fuego
        .auth()
        .signInWithCustomToken(cookies.token)
        .then((userCredential) => {
          // Signed in
          var newuser = userCredential.user;
          console.log("newuser", newuser);
          // ...
        })
        .catch((error) => {
          var errorCode = error.code;
          var errorMessage = error.message;
          // ...
        });
    }
  }, [cookies, firebase]); // Only re-run the effect if count changes

  return (
    <>
      <Router>
        <NavBar removeCookie={removeCookie} />
        <Container align="center">
          <br />
          {firebase && firebase.user ? (
            <>
              <Button
                size="small"
                variant="contained"
                href="https://discord.gg/WxYaA8vT7S"
                target="_blank"
              >
                Open chat in Browser
              </Button>
              &nbsp;&nbsp;&nbsp;&nbsp;
              <Button
                size="small"
                variant="contained"
                href="discord://discord.com/channels/802727441646092288/807826642935349279"
                target="_blank"
              >
                Open chat in App
              </Button>
            </>
          ) : null}
          <br /> <br />
          <Switch>
            <Route path="/" exact>
              {firebase && firebase.user ? (
                <Map memberId={memberId} />
              ) : (
                <>
                  <Welcome />
                </>
              )}
            </Route>
            <Route>
              <Redirect to="/" />
            </Route>
          </Switch>
        </Container>
      </Router>
    </>
  );
}

export default App;
