import React, { useState, useEffect, useContext, createContext } from "react";
import "firebase/firestore";
import "firebase/functions";
import "firebase/auth";
import { FuegoProvider } from "@nandorojo/swr-firestore";
import { Fuego } from "./fuego";
import firebaseConfig from "../config/firebaseConfig";

// Add your Firebase credentials
const fuego = new Fuego(firebaseConfig);
console.log(fuego);

// Turn on firestore persistance, while we are here...
fuego.db.enablePersistence();

const authContext = createContext();

// Provider component that wraps your app and makes auth object ...
// ... available to any child component that calls useAuth().
export function ProvideAuth({ children }) {
  const auth = useProvideAuth();
  return (
    <FuegoProvider fuego={fuego}>
      <authContext.Provider value={auth}>{children}</authContext.Provider>
    </FuegoProvider>
  );
}

// Hook for child components to get the auth object ...
// ... and re-render when it changes.
export const useAuth = () => {
  return useContext(authContext);
};

// Provider hook that creates auth object and handles state
function useProvideAuth() {
  const [user, setUser] = useState(null);

  // Wrap any Firebase methods we want to use making sure ...
  // ... to save the user to state.
  const signin = (email, password) => {
    return fuego
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then((response) => {
        setUser(response.user);
        return response.user;
      });
  };

  const signup = (email, password) => {
    return fuego
      .auth()
      .createUserWithEmailAndPassword(email, password)
      .then((response) => {
        setUser(response.user);
        return response.user;
      });
  };

  const signout = () => {
    return fuego
      .auth()
      .signOut()
      .then(() => {
        setUser(false);
      });
  };

  const sendPasswordResetEmail = (email) => {
    return fuego
      .auth()
      .sendPasswordResetEmail(email)
      .then(() => {
        return true;
      });
  };

  const confirmPasswordReset = (code, password) => {
    return fuego
      .auth()
      .confirmPasswordReset(code, password)
      .then(() => {
        return true;
      });
  };

  if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
    fuego.functions().useEmulator("localhost", 5001);
    fuego.auth().useEmulator("http://localhost:9099/");
  }

  // Subscribe to user on mount
  // Because this sets state in the callback it will cause any ...
  // ... component that utilizes this hook to re-render with the ...
  // ... latest auth object.
  useEffect(() => {
    const unsubscribe = fuego.auth().onAuthStateChanged((user) => {
      if (user) {
        setUser(user);
      } else {
        setUser(false);
      }
    });

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, []);

  // Return the user object and auth methods
  return {
    user,
    signin,
    signup,
    signout,
    sendPasswordResetEmail,
    confirmPasswordReset,
    fuego,
  };
}
